import { FC, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";

import { Button } from "@salutejs/plasma-b2c";

import { useModal } from "../utils/hooks";
import { IModel } from "../utils/types";

import { Wrapper } from "../components/PageWrapper";
import { Header } from "../components/Header";
import { Icon } from "../components/Icon";
import { WidgetModal } from "../components/WidgetModal";
import { ModelGlb } from "../components/ModelGlb";

import { downloadModel } from "../utils/libs";
import { useStateContext } from "../utils/context";
import { SIZES } from "../utils/breakpoints";
import axios from "axios";

const ITEM_DROPDOWN_VALUE = {
  copy: "copy",
  download: "download",
};

const StyledPageWrapper = styled(Wrapper)<{ isFullScreenModel: boolean }>`
  display: flex;
  padding-top: 0;
  grid-template: none;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  > div {
    &:first-child {
      margin-top: ${({ isFullScreenModel }) =>
        isFullScreenModel ? "0" : "28px"};
      position: relative;
    }
  }

  @media (max-width: ${SIZES.sm}px) {
    flex-direction: column;

    > div {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const ModelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${SIZES.sm}px) {
    height: 100%;
  }
`;

const ArrowSlide = styled(Button)<{ isRight?: boolean }>`
  position: absolute;
  z-index: 1;

  ${({ isRight }) => (isRight ? "right:0" : "left:0")};

  &:hover {
    path {
      stroke-opacity: 1;
    }
  }
`;

const Close = styled(Button)`
  position: absolute;
  right: 0;
  z-index: 1;
  width: auto;
  top: 24px;
`;

export const RudalleGlbPage: FC = () => {
  const { isFullScreenModel } = useStateContext();

  const [isOpenWidgetModal, openWidgetModal, closeWidgetModal] = useModal();

  const [model, setModel] = useState<IModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>("");

  const [params] = useSearchParams();

  const { pathGlb } = useMemo(() => ({ pathGlb: params.get("glb") }), [params]);

  const checkModel = async (pathGlb: string) => {
    try {
      const res = await axios.get(pathGlb);
      console.log("checkModel---res", res);
      setIsLoading(false);
    } catch (error) {
      console.log("checkModel---error", error);
      setError("Модель не найдена");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (pathGlb) {
  //     checkModel(pathGlb);
  //   }
  // }, [pathGlb]);

  return (
    <StyledPageWrapper isFullScreenModel={isFullScreenModel}>
      {/* {!isFullScreenModel && (
        <div>
          <Header closeSrc={undefined} />
          <WidgetModal
            uid={model?.uid}
            isOpenWidgetModal={isOpenWidgetModal}
            closeWidgetModal={closeWidgetModal}
          />
          <InfoPanel model={model} isGlb />
        </div>
      )} */}

      {error && <div>{error}</div>}

      {!isLoading && !error && (
        <ModelWrapper>
          <ModelGlb pathGlb={pathGlb ?? ""} isLoading={false} />
        </ModelWrapper>
      )}
    </StyledPageWrapper>
  );
};
