export const PATHS = {
  home: "/",
  all: "/all",
  userModel: "/:nickname",
  model: "/models/:uid",
  debuggingModel: "/debugging",
  iframe: "/iframe/:uid",
  iframeGlb: "/iframe-glb/:uid",
  scene: "/scene",
  ply: "/ply",
  plyUid: "/ply/:uid",
  glb: "/glb/:uid",
  rudalleGlb: "/rudalle-glb",
};

export const MODELS_QUANTITY_ON_REQUEST = 10;
export const MIN_DISTANCE_ON_BOTTOM_PAGE_FOR_REQ = 100;

export const DISABLED_PROMO = "disabledPromo";

export const ShaderVersions = {
  first: 1,
  second: 2,
};

export const SECRET_TOKEN = "guCnqoIJ0C";
