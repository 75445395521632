import { FC, useCallback, useState } from "react";
import {
  Environment,
  OrbitControls,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";

import styled from "styled-components/macro";
import {
  Button,
  Checkbox,
  Headline3,
  Image,
  Modal,
  ModalsProvider,
  Select,
  Spinner,
} from "@salutejs/plasma-b2c";

import { IModel } from "../../utils/types";
import { axiosCdnBaseUrl } from "../../utils/axios";

import { ModelNotFound } from "../ModelNotFound";
import { Color, Mesh, MeshStandardMaterial } from "three";

import hangar_interior_2k from "../../assets/hangar_interior_2k.hdr";
import modern_buildings_2_2k from "../../assets/modern_buildings_2_2k.hdr";
import overcast_soil_puresky_2k from "../../assets/overcast_soil_puresky_2k.hdr";
import studio_small_09_2k from "../../assets/studio_small_09_2k.hdr";
import thatch_chapel_2k from "../../assets/thatch_chapel_2k.hdr";
import { useMovement } from "../../utils/hooks";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ProgressiveImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    filter: blur(10px);
    clip-path: inset(0);
    object-fit: contain;
  }
`;

const WrapperHdr = styled.div`
  position: absolute;
  bottom: 4px;
  z-index: 3;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HelpPositionWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, 0);
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.15);
`;

interface PropsModel {
  path: string;
  isShowTexture: boolean;
}

const STEP_ROTATION = 0.027;

const Model: FC<PropsModel> = ({ path, isShowTexture }) => {
  const { scene } = useGLTF(path);

  const { left, right } = useMovement();

  scene.traverse((child) => {
    if (child instanceof Mesh) {
      if (child.material instanceof MeshStandardMaterial) {
        child.material.metalness = 0;
        if (isShowTexture) {
          child.material.color = new Color(1, 1, 1);
        } else {
          child.material.color = new Color(255, 255, 255);
        }
      }
    }
  });

  useFrame((state) => {
    if (left) {
      state.scene.rotation.y = state.scene.rotation.y + STEP_ROTATION;
    }
    if (right) {
      state.scene.rotation.y = state.scene.rotation.y - STEP_ROTATION;
    }
  });

  return <primitive object={scene} />;
};

const Sphere = () => {
  return (
    <>
      <mesh position={[0, 2, 0]}>
        <sphereBufferGeometry args={[0.2, 24, 24]} />
        <meshPhysicalMaterial color={"white"} metalness={1} roughness={1} />
      </mesh>

      <mesh position={[0.5, 2, 0]}>
        <sphereBufferGeometry args={[0.2, 24, 24]} />
        <meshPhysicalMaterial color={"white"} metalness={1} roughness={0.1} />
      </mesh>

      {/* <mesh position={[1, 2, 0]}>
        <sphereBufferGeometry args={[0.2, 24, 24]} />
        <meshPhysicalMaterial color={"white"} metalness={1} roughness={0.1} />
      </mesh> */}
    </>
  );
};

interface PropsScene {
  path: string;
  env: string;
  isShowSphere: boolean;
  isShowTexture: boolean;
}
const Scene: FC<PropsScene> = ({ path, env, isShowSphere, isShowTexture }) => {
  return (
    <Canvas camera={{ position: [0, 10, 10], fov: 17.5, near: 1, far: 500 }}>
      {env && <Environment background files={env} blur={0.06} />}

      <OrbitControls />

      <ambientLight />

      {isShowSphere && <Sphere />}

      <Model path={path} isShowTexture={isShowTexture} />
    </Canvas>
  );
};

const items = [
  { value: "", label: "default" },
  { value: thatch_chapel_2k, label: "thatch_chapel_2k" },
  { value: overcast_soil_puresky_2k, label: "overcast_soil_puresky_2k" },
  { value: modern_buildings_2_2k, label: "modern_buildings_2_2k" },
  { value: hangar_interior_2k, label: "hangar_interior_2k" },
  { value: studio_small_09_2k, label: "studio_small_09_2k" },
];
interface Props {
  model?: IModel;
  isLoading: boolean;
  pathGlb?: string;
}

export const ModelGlb: FC<Props> = ({ model, isLoading, pathGlb }) => {
  const progress = useProgress(({ progress }) => progress);

  const [chooseEnv, setChooseEnv] = useState<string>("");
  const [isShowSphere, setIsShowSphere] = useState<boolean>(false);
  const [isShowTexture, setIsShowTexture] = useState<boolean>(true);

  const [isOpenA, setIsOpenA] = useState(false);

  const onCloseA = useCallback(() => setIsOpenA(false), []);

  return (
    <Wrapper>
      {isLoading && <Spinner />}

      {progress !== 100 && model && (
        <ProgressiveImage src={`${axiosCdnBaseUrl}${model?.path_preview}`} />
      )}

      {model?.path_glb ? (
        <>
          <WrapperHdr>
            <ModalsProvider>
              <Button text="HDR настройки" onClick={() => setIsOpenA(true)} />

              <Modal
                style={{ minWidth: "340px" }}
                isOpen={isOpenA}
                onClose={onCloseA}
              >
                <WrapperSelect>
                  <Headline3 mb={10}>HDR настройки</Headline3>

                  <Select
                    value={chooseEnv}
                    items={items}
                    onChange={(v) => {
                      setChooseEnv(v);
                    }}
                  />

                  <Checkbox
                    label="hdr sphere"
                    checked={isShowSphere}
                    onChange={({ target: { checked } }) => {
                      setIsShowSphere(checked);
                    }}
                  />
                  <Checkbox
                    label="show texture"
                    checked={isShowTexture}
                    onChange={({ target: { checked } }) => {
                      setIsShowTexture(checked);
                    }}
                  />
                </WrapperSelect>
              </Modal>
            </ModalsProvider>
          </WrapperHdr>

          <Scene
            path={axiosCdnBaseUrl + model.path_glb}
            env={chooseEnv}
            isShowSphere={isShowSphere}
            isShowTexture={isShowTexture}
          />
        </>
      ) : pathGlb ? (
        <Scene
          path={pathGlb ?? ""}
          env={chooseEnv}
          isShowSphere={isShowSphere}
          isShowTexture={isShowTexture}
        />
      ) : (
        <ModelNotFound />
      )}
    </Wrapper>
  );
};
